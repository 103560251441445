import './App.scss';
import Navigation from "./components/navigation/Navigation";
import Main from './components/main/Main';
import Location from './components/location/Location';
import About from './components/about/About';
import Footer from './components/footer/Footer'
import Equipment from './components/equipment/Equipment';
import Services from './components/services/Services';
import Top from "./components/top/Top";
import Nayax from './components/nayax/Nayax';
import Contact from './components/contact/Contact';
import Sale from './components/sale/Sale';

function App() {
  
  return (
    <div className="App">
      <Navigation />
      <Main id="main" />
      <Location id="location" />
      <About id="about" />
      <Nayax />
      <Services />
      <Equipment />
      <Sale id="sale" />
      <Contact />
      <Footer />
      <Top />
    </div>
  );
}



export default App;
