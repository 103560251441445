import "./Nayax.scss";
import nayax1 from "../../assets/images/nayaxapp.webp";

function Nayax() {
  return (
    <>
      <div className="nayax">
        <h2 className="nayax__heading">Remote Inventory Monitoring</h2>
        <div className="nayax__container">
          <img
            className="nayax__image"
            src={nayax1}
            alt="Nayax payment solutions"
          />
          <div className="nayax__copy">
            Upgrade Your Vending Machine Experience with Nayax's Innovative
            Payment and Inventory Management Solutions. Enjoy Seamless Cashless
            Transactions and Automated Inventory Tracking. With <a href="https://nayax.com" target="_blank" className="nayax__link" rel="noreferrer">Nayax</a>, Elevate
            Customer Convenience with Credit/Debit Cards, Mobile Wallets, and
            Contactless Payments. Gain Real-Time Insights into Product Levels,
            Sales Trends, and Machine Performance to Optimize Offerings and
            Boost Profitability. Embrace the Future of Vending with Nayax and
            Propel Your Business Forward
          </div>
        </div>
      </div>
    </>
  );
}

export default Nayax;
