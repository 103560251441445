import "./Footer.scss";


function Footer() {
 

  return (
    <>
      <div className="footer">
        <div className="footer__container">
          <div className="footer__socials">
            <h2 className="footer__socials--heading">Follow Us</h2>{" "}
            <div className="footer__socials--container">
            <a
              href="https://www.facebook.com/arjun.budial"
              target="_blank"
              rel="noreferrer"
              className="footer__fb"
            >
              {" "}
              <div className="footer__fb"></div>
            </a>{" "}
            <a
              href="https://www.instagram.com/wholesale_vendingbc/"
              target="_blank"
              rel="noreferrer"
              className="footer__insta"
            >
              {" "}
              <div className="footer__insta"></div>
            </a>{" "}
            </div>
          </div>

          
          <p className="footer__copy">&copy; 2024 Wholesale Vending BC</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
