import { useEffect, useState, useRef } from "react";
import "./Navigation.scss";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const dropdownRef = useRef();

  const handleToggle = () => {
      setIsOpen(!isOpen);
    }


  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 100;
      setShowNav(scrollY <= scrollThreshold);
      if (isOpen) {
        setIsOpen(false);
      }
    };

    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);



  return (
    <header className="header">
      <nav
        className="nav"
        style={{ opacity: showNav ? 1 : 0, transition: "opacity 0.5s" }}
      >
        <h2 className="logo-link">
          Wholesale Vending BC
        </h2>

        <div className="hamburger" onClick={handleToggle}>
          <span></span>
        </div>

        <ul className={`list ${isOpen ? "open" : ""} `}>
          <li className="list__item">
            <a className="link" href="#about">
              About
            </a>
          </li>
          <li className="list__item">
            <a className="link" href="#services">
              Services
            </a>
          </li>
          <li className="list__item">
            <a className="link" href="#equipment">
              Equipment
            </a>
          </li>
          <li className="list__item">
            <a className="link" href="#location">
              Locations
            </a>
          </li>
          <li className="list__item">
            <a className="list__item--contact" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>
      {/* <Modal modalOpen={modalOpen} modalClose={closeModal} /> */}
    </header>
  );
}

export default Navigation;
