import { useState } from "react";
import "./Location.scss";

function Location() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <div className="location">
        <h3 className="location__heading">
          Transform your workplace with a free vending machine!{" "}
        </h3>
        <button onClick={handleModalToggle} className="location__button">
          Click to discover how!
        </button>
      </div>
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleModalToggle}>
              &times;
            </span>
            <h2>Call for a Quote</h2>
            <p>
              Call now to find out how we can provide your business with a free
              vending machine. Elevate your workplace with convenient snacks and
              beverages for your employees and customers. Don't miss out on this
              opportunity to boost morale and productivity with hassle-free
              vending solutions. Contact us today to learn more!
            </p>
            <div className="location__buttons">
            <button className="location__button">
              {" "}
              <a className="location__contact--phone" href="tel:+16042839820">
                Give Us A Call
              </a>
            </button>
            <button className="location__button" onClick={handleModalToggle}>
            <a className="location__contact--phone" href="#contact" >
              Contact
            </a>
            </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Location;
