import "./About.scss";
import vending from "../../assets/images/greenmachine.webp";


function About() {
  return (
    <>
      <div id="about" className="about">
        <img src={vending} alt="Vending Machine" className="about__pic" />
        <div className="about__heading">
          <h2 className="about__heading--h2">About Us</h2>
          <h2>Based in Vancouver, Wholesale Vending BC</h2> 
          <p className="about__copy"> is your
          local vending machine expert. We provide customized solutions for
          businesses, offering a variety of snacks and drinks. With reliable
          service and diverse options, we make satisfying cravings easy and
          convenient. Choose Wholesale Vending BC for hassle-free refreshment solutions in
          the Lower Mainland.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
